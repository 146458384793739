import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import '../styles/general.scss';

interface FormData {
  names?: string;
  email?: string;
  //phone?: string;
  //company?: string;
  description?: string;
  terms?: boolean;
}

interface Translations {
  title: string;
  subTitle: string;
  labelNames: string;
  email: string;
  phone: string;
  company: string;
  description: string;
  contactBtn: string;
  terms: string;
}

interface ContactFormProps {
  translations: Translations;
}

const MyForm: React.FC<ContactFormProps> = ({ translations }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>();

  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | null>(null); // Estado para manejar el mensaje de éxito o error


  const onSubmit = async (data: FormData) => {
    //console.log(data);
    setIsSubmitting(true);
    
    try {
      const response = await fetch('https://fybnme7oz0.execute-api.us-east-1.amazonaws.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Aquí puedes agregar un header de autorización si lo necesitas
        },
        body: JSON.stringify(data), // Los datos que tu Lambda espera
      });
      reset();
      const result = await response.json();
      //console.log('Email enviado con éxito:', result);
      setIsSubmitting(false)
      setSubmissionStatus('success');
    } catch (error) {
      //console.error('Error al enviar el email:', error);
      setIsSubmitting(false)
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false); // Se desactiva cuando la solicitud ha terminado
      setTimeout(() => {
        setSubmissionStatus(null);
      }, 5000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6 flex flex-col">
          <label htmlFor="names" className="text-neutral-700 text-sm md:text-md mb-1">
            {translations.labelNames}
          </label>
          <input
            id="names"
            type="text"
            className={`rounded-lg border-[1px] border-solid ${
              errors.names ? 'border-red-500' : 'border-neutral-700'
            } py-1 px-2 box-border`}
            {...register('names', {
              required: 'Este campo es obligatorio',
              minLength: {
                value: 3,
                message: 'Mínimo 3 caracteres requeridos',
              },
            })}
          />
          {errors.names && <span className="text-red-500 text-sm">{errors.names.message}</span>}
        </div>
        <div className="col-span-6 flex flex-col">
          <label htmlFor="email" className="text-neutral-700 text-sm md:text-md mb-1">
            {translations.email}
          </label>
          <input
            id="email"
            type="email"
            className={`rounded-lg border-[1px] border-solid ${
              errors.email ? 'border-red-500' : 'border-neutral-700'
            } py-1 px-2 box-border`}
            {...register('email', {
              required: 'Este campo es obligatorio',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Introduce un correo electrónico válido',
              },
            })}
          />
          {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
        </div>
        {/* 
        <div className="col-span-6 flex flex-col">
            <label htmlFor="phone" className="text-neutral-700 text-sm md:text-md mb-1">
              {translations.phone}
            </label>
            <input
              id="phone"
              type="tel"
              className={`rounded-lg border-[1px] border-solid ${
                errors.phone ? 'border-red-500' : 'border-neutral-700'
              } py-1 px-2 box-border`}
              placeholder="+123 456 789" // Ejemplo de formato
              onInput={handlePhoneInput}
              {...register('phone', {
                required: 'Este campo es obligatorio',
                pattern: {
                  value: /^\+?\d+(?: \d+)*$/,
                  message: 'Introduce un número de teléfono válido en formato E.164',
                },
                maxLength: {
                  value: 15,
                  message: 'Máximo 15 caracteres permitidos',
                },
              })}
            />
            {errors.phone && <span className="text-red-500 text-sm">{errors.phone.message}</span>}
        </div>
        <div className="col-span-6 flex flex-col">
          <label htmlFor="company" className="text-neutral-700 text-sm md:text-md mb-1">
            {translations.company}
          </label>
          <input
            id="company"
            type="text"
            className={`rounded-lg border-[1px] border-solid ${
              errors.company ? 'border-red-500' : 'border-neutral-700'
            } py-1 px-2 box-border`}
            {...register('company', {
              required: 'Este campo es obligatorio',
              minLength: {
                value: 2,
                message: 'Mínimo 2 caracteres requeridos',
              },
            })}
          />
          {errors.company && <span className="text-red-500 text-sm">{errors.company.message}</span>}
        </div>
        */}
        <div className="col-span-12 flex flex-col">
          <label htmlFor="description" className="text-neutral-700 text-sm md:text-md mb-1">
            {translations.description}
          </label>
          <textarea
            id="description"
            rows={4}
            className="rounded-lg border-[1px] border-solid border-neutral-700 py-1 px-2 box-border"
            {...register('description')} // No hay validaciones aquí
          />
        </div>
        <div className="col-span-12 flex gap-4">
          <input
            type="checkbox"
            id="terms"
            className="rounded-2xl border-[1px] border-solid border-neutral-700 p-2 w-6 h-6 box-border"
            {...register('terms', { required: 'Debes aceptar los términos' })}
          />
          <label htmlFor="terms" className="text-neutral-700 text-sm md:text-md" dangerouslySetInnerHTML={{ __html: translations.terms }}>            
          </label>
          {errors.terms && <span className="text-red-500 text-sm">{errors.terms.message}</span>}
        </div>
        <div className="col-span-12 flex py-4"> 
          <button
            type="submit"
            className="py-3 btn-self px-12 text-center w-fit border-none rounded-lg bg-orange-600 bg-gradient-btn text-xl md:text-2xl m-0 top-0 bottom-0 btn-shadow text-white"
            aria-label="Submit form"
            disabled={isSubmitting}
          >
            <span>
              {translations.contactBtn}
            </span>            
          </button>
          {/*incluir indicativo y formateo para campos teléfonicos*/}
        </div>
        {/* Mensaje de éxito o error */}
      {submissionStatus && (
        <div className="my-4 mb-24 sm:mb-20 lg:mb-0 text-center col-span-12 flex">
          {submissionStatus === 'success' ? (
            <p className="text-green-600">¡Tu mensaje ha sido enviado exitosamente!</p>
          ) : (
            <p className="text-red-600">Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo.</p>
          )}
        </div>
      )}
      </div>
    </form>
  );
};

export default MyForm;
